export const routeVariables = {
    INDUSTRY  : '-free-certificate-courses-',
    SUBINDUSTRY : '-free-certificate-course-',
    COURSEDETAIL : '-free-course-',
    SUBTOPIC : '-sub-topic-',
    TOPICDETAIL : '-topic-',
    ARTICLE : '-article-',
    PREMIUMCOURSE: ['-mentorship-program-', '-self-paced-program-', '-workshop-'],
    PREMIUMINDUSTRY : '-premium-courses-',
    PREMIUMSUBINDUSTRY : '-premium-course-',
}

export type RouteVariants = keyof typeof routeVariables;


export const RouteStrings = {
    FREECOURSE : 'courses',
    DYNAMICROUTE  : (slug: string, routeVar: RouteVariants , id: number) => `${slug?.toLowerCase()?.replace(/\s+/g, "-").replace(/-+/g, "-").replaceAll(":", "").replaceAll("?", "")}${routeVariables[routeVar]}${id}`,
    TOPICSINDEX : 'topics',
}

export const sanitizeUrlSegment = (segment: string): string => {
    return segment
        ?.toLowerCase()
        ?.replace(/[\s:]/g, '-') // Replace spaces and colons with hyphens
        ?.replace(/[^\w\-]/g, '') // Remove all non-alphanumeric characters (except hyphens and underscores)
        ?.replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
        ?.replace(/^-|-$/g, ''); // Remove hyphens from the start or end
};